import heartGreenIcon from '../../images/icons/heart-green.svg'
import heartFilledGreenIcon from '../../images/icons/heart-filled-green.svg'
import heartFilledBlackIcon from '../../images/icons/heart-filled-black.svg'
import heartBlackIcon from '../../images/icons/heart-black.svg'
import heartDarkGreyIcon from '../../images/icons/heart-dark-grey.svg'
import heartFilledDarkGreyIcon from '../../images/icons/heart-filled-dark-grey.svg'

export default function renderWishlistButton (listingId, wishlistItemId, isLoggedIn, type) {
  // TODO: come back later - we need to set stuff on login modal to keep the listing id for wishlist etc
  // including passing stuff to facebook login query string, to keep flow - check WishListItemContainer.jsx
  if (wishlistItemId) {
    return renderRemoveFromWishlistButton(listingId, wishlistItemId, type)
  } else if (!isLoggedIn) {
    return renderAddToWishlistButtonForGuests(listingId, type)
  } else {
    return renderAddToWishlistButton(listingId, type)
  }
}

function renderAddToWishlistButtonForGuests (listingId, type) {
  const guestWishlistAllowedEl = document.querySelector('#vjs-guest-wishlist-allowed')
  const guestWishlistAllowed = JSON.parse(guestWishlistAllowedEl?.value)
  if (guestWishlistAllowed) {
    return addToDefaultWishlistButton(listingId, type)
  } else {
    return pleaseLoginButton(listingId, type)
  }
}

function addToDefaultWishlistButton (listingId, type) {
  return `
    <button
      type="button"
      class="vjs-guest-add-to-wishlist vjs-listing-tile-add-to-wishlist-button ${extraWishlistButtonClasses(type)}"
      data-button-type="${type}"
      data-listing-id="${listingId}">
      ${renderEmptyHeartIcon(type)}
    </button>
  `
}

function renderFilledHeartIcon (type) {
  if (type === 'variant-c-over-listing-images' || type === 'variant-b-atc-adjacent' || type === 'variant-a-wide-with-text') {
    return `<img src="${heartFilledBlackIcon}" class="inline relative w-24 h-24 mb-2">`
  } else if (type === 'big') {
    return `<img src="${heartFilledBlackIcon}" class="inline vsd-wishlist-heart-icon">`
  } else if (type === 'within-image') {
    return `<img src="${heartFilledDarkGreyIcon}" class="inline vsd-wishlist-heart-icon">`
  } else {
    return `<img src="${heartFilledGreenIcon}" class="vsd-wishlist-heart-icon">`
  }
}

function renderEmptyHeartIcon (type) {
  if (type === 'variant-c-over-listing-images' || type === 'variant-b-atc-adjacent' || type === 'variant-a-wide-with-text') {
    return `<img src="${heartBlackIcon}" class="inline relative w-24 h-24 mb-2">`
  } else if (type === 'big') {
    return `<img src="${heartBlackIcon}" class="inline vsd-wishlist-heart-icon">`
  } else if (type === 'within-image') {
    return `<img src="${heartDarkGreyIcon}" class="inline vsd-wishlist-heart-icon">`
  } else {
    return `<img src="${heartGreenIcon}" class="vsd-wishlist-heart-icon">`
  }
}

function extraWishlistButtonClasses (type) {
  if (type === 'variant-c-over-listing-images') {
    return 'vsd-button__outlined w-56 h-48 p-0 shadow-md border-midnight-black-60 bg-white'
  } else if (type === 'variant-b-atc-adjacent') {
    return 'vsd-button__outlined p-12'
  } else if (type === 'variant-a-wide-with-text') {
    return 'vsd-button__outlined w-full flex gap-8 justify-center'
  } else if (type === 'big') {
    return 'vsd-button__outlined vsd-wishlist-button--smaller'
  } else if (type === 'within-image') {
    return 'vsd-listing__wishlist-icon-within-image'
  } else {
    return 'vsd-button__text vsd-listing__wishlist-icon shrink-0'
  }
}

function renderAddToWishlistButton (listingId, type) {
  return `
    <button
      type="button"
      class="vjs-modal-button vjs-wishlist-modal vjs-listing-tile-add-to-wishlist-button ${extraWishlistButtonClasses(type)}"
      data-button-type="${type}"
      data-modal="add-to-wishlist"
      data-listing-id="${listingId}">
      ${renderEmptyHeartIcon(type)}
      ${type === 'variant-a-wide-with-text' ? '<span>Add to wishlist</span>' : ''}
    </button>
  `
}

function renderRemoveFromWishlistButton (listingId, wishlistItemId, type) {
  return `
    <button
      type="button"
      class="vjs-listing-wishlisted ${extraWishlistButtonClasses(type)}"
      data-button-type="${type}"
      data-wishlisted-item-id="${wishlistItemId}"
      data-listing-id="${listingId}">
      ${renderFilledHeartIcon(type)}
      ${type === 'variant-a-wide-with-text' ? '<span>Added to wishlist</span>' : ''}
    </button>
  `
}

function pleaseLoginButton (listingId, type) {
  return `
    <button
      type="button"
      class="vjs-modal-button vjs-wishlist-login vjs-listing-tile-add-to-wishlist-button ${extraWishlistButtonClasses(type)}"
      data-button-type="${type}"
      data-listing-id="${listingId}"
      data-modal="login-modal">
      ${renderEmptyHeartIcon(type)}
    </button>
  `
}
